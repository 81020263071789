import React from 'react';
import ReactDOM from 'react-dom';
import { Helmet } from 'react-helmet';

import { ThemeProvider, GlobalStyle } from '@kali/theme/lib/provider';
import { Layout as LayoutView } from '@kali/theme/lib/layout/layout';

export default class Layout extends React.PureComponent {
  state = {
    isMenuOpen: false,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    ReactDOM.findDOMNode(this).scrollIntoView();
  }

  onMenuToggle = () => {
    const { isMenuOpen } = this.state;
    this.setState({
      isMenuOpen: !isMenuOpen,
    });
  };

  render() {
    const { isMenuOpen } = this.state;
    const { children, useLayoutView = true, options, meta, title } = this.props;
    const { layout = {} } = options || {};

    const nav = [
      {
        text: 'Portfolio',
        href: '/portfolio',
        onClick: this.onMenuToggle,
      },
      {
        text: 'About',
        href: '/about',
        onClick: this.onMenuToggle,
      },
      {
        text: 'Experience',
        href: '/experience',
        onClick: this.onMenuToggle,
      },

      {
        text: 'Shop',
        href: '/shop',
        onClick: this.onMenuToggle,
      },

      {
        text: 'Blog',
        href: '/blog',
        onClick: this.onMenuToggle,
      },
      {
        text: 'Contact',
        href: '/contact',
        onClick: this.onMenuToggle,
      },
    ];

    const wrappedChildren = !useLayoutView ? (
      children
    ) : (
      <LayoutView
        navItems={nav}
        {...layout}
        isMenuOpen={isMenuOpen}
        onToggleMenu={this.onMenuToggle}
      >
        {children}
      </LayoutView>
    );

    return (
      <ThemeProvider>
        <React.Fragment>
          <Helmet defaultTitle="" titleTemplate={`%s Kalina & Co.`.trim()}>
            <title>{title && `${title} | `}</title>
          </Helmet>
          <GlobalStyle />
          {wrappedChildren}
        </React.Fragment>
      </ThemeProvider>
    );
  }
}
